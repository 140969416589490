<template>
    <div id="applicationRegistration">
        <h1 class="subtitle is-3 text-center mt-3 mb-0">Заявка</h1>
      <h1 class="subtitle is-4 text-center mt-0 mb-3">перевозчик</h1>
        <div class="columns">
            <div class="column is-4 is-offset-4">
                <section>

                  <b-field
                    expanded
                    class="mb-0">
                    <v-text-field
                      type="text"
                      label="Имя"
                      v-model="dataApplication.name"
                      outlined>
                    </v-text-field>
                  </b-field>

                  <b-field
                    label="Телефон для связи"
                    expanded
                    class="mb-0">
                    <v-text-field
                      type="phone"
                      label="Телефон"
                      prefix="+7"
                      v-model="dataApplication.number"
                      v-mask="'##########'"
                      inputmode="tel"
                      outlined>
                    </v-text-field>
                  </b-field>

                  <b-field
                    expanded
                    class="mb-0">
                    <v-text-field
                      type="text"
                      label="Комментарий"
                      v-model="dataApplication.comment"
                      outlined>
                    </v-text-field>
                  </b-field>


                  <!-- Свидетельство о регистрации ТС -->
                  <div>
                    <h1 class="subtitle is-6 mt-0 mb-2">Фото свидетельства о <br>регистрации ТС с двух строн</h1>

                    <b-field>
                      <b-upload v-model="vehicle_registration"
                                multiple
                                drag-drop
                                accept="image/png, image/jpeg, image/jpg"
                      >
                        <section class="section">
                          <div class="content has-text-centered">
                            <p>
                              <b-icon
                                icon="upload"
                                size="is-large">
                              </b-icon>
                            </p>
                            <p>Нажмите и выберите файл</p>
                          </div>
                        </section>
                      </b-upload>
                    </b-field>

                    <div class="tags mb-3">
                    <span
                      v-for="(file, index) in vehicle_registration"
                      :key="index"
                      class="tag is-primary" >
                      {{file.name}}
                      <button
                        class="delete is-small"
                        type="button"
                        @click="deleteDropFile(vehicle_registration, index)">
                      </button>
                    </span>
                    </div>
                  </div>


                  <!-- Водительское удостоверение -->
                  <div>
                    <h1 class="subtitle is-6 mt-0 mb-2">Фото водительского <br>удостоверения с двух строн</h1>

                    <b-field>
                      <b-upload v-model="drivers_license"
                                multiple
                                drag-drop
                                accept="image/png, image/jpeg, image/jpg"
                      >
                        <section class="section">
                          <div class="content has-text-centered">
                            <p>
                              <b-icon
                                icon="upload"
                                size="is-large">
                              </b-icon>
                            </p>
                            <p>Нажмите и выберите файл</p>
                          </div>
                        </section>
                      </b-upload>
                    </b-field>

                    <div class="tags mb-3">
                    <span
                      v-for="(file, index) in drivers_license"
                      :key="index"
                      class="tag is-primary" >
                      {{file.name}}
                      <button
                        class="delete is-small"
                        type="button"
                        @click="deleteDropFile(drivers_license, index)">
                      </button>
                    </span>
                    </div>
                  </div>


                  <!-- Эвакуатор -->
                  <div>
                    <h1 class="subtitle is-6 mt-0 mb-2">Фото эвакуатора, где видно гос.номер</h1>

                    <b-field>
                      <b-upload v-model="evacuator"
                                multiple
                                drag-drop
                                accept="image/png, image/jpeg, image/jpg"
                      >
                        <section class="section">
                          <div class="content has-text-centered">
                            <p>
                              <b-icon
                                icon="upload"
                                size="is-large">
                              </b-icon>
                            </p>
                            <p>Нажмите и выберите файл</p>
                          </div>
                        </section>
                      </b-upload>
                    </b-field>

                    <div class="tags mb-3">
                    <span
                      v-for="(file, index) in evacuator"
                      :key="index"
                      class="tag is-primary" >
                      {{file.name}}
                      <button
                        class="delete is-small"
                        type="button"
                        @click="deleteDropFile(evacuator, index)">
                      </button>
                    </span>
                    </div>
                  </div>

                </section>

                <div class="text-center">
                    <b-button
                      class="mt-0 w-100"
                      type="is-primary"
                      @click="sendForm">
                      Отправить
                    </b-button>
                </div>
            </div>
        </div>

      <FullScreenLoader :show="isLoading" />

    </div>
</template>

<script>

export default {
  name: 'ApplicationRegistration',
  data() {
    return {
      dataApplication: {
        name: '',
        number: '',
        comment: ''
      },
      vehicle_registration: [],
      drivers_license: [],
      evacuator: [],
      isLoading: false
    };
  },
  created() {
    this.isLoading = false;

    console.log('Тип авторизации: ', this.$store.getters.AUTH_TYPE);
    if (this.$store.getters.AUTH_TYPE == 0) {
      this.$store.commit('SET_REGISTRATION_FROM', 'loginTransporter');
      // обычная регистрация
      this.$router.push('/registration');
    } else if (this.$store.getters.AUTH_TYPE == 1) {
      this.$store.commit('SET_REGISTRATION_FROM', 'loginTransporter');
      // подтверждение телефона
      this.$router.push('/verification-code');
    }

    // иначе остаёмся на странице и даём заполнить
    this.dataApplication.number = this.phoneWrapper(this.$store.getters.PROFILE_DETAILS.number);
  },
  methods: {
    phoneWrapper(phone) {
      if (phone && phone !== '') {
        if (phone[0] === '+') {
          return phone.substring(2)
        } else if (phone[0] === '7') {
          return phone.substring(1)
        }
        return phone
      }
      return '';
    },
    sendForm() {
      let dataIsValid = this.validateForm();

      if (dataIsValid !== true) {
        this.$buefy.toast.open({
          message: dataIsValid,
          type: 'is-danger'
        });
        return;
      }

      // Создание временного массива для передачи в API
      // TODO: Добавить в массив фото эвакуатора, когда Миша уберёт ограничние
      const tempFilesArray = [...this.vehicle_registration, ...this.drivers_license];

      const apiData = {
        'apiParams': {...this.dataApplication},
        'body': tempFilesArray
      };

      console.log('apiParams: ');
      console.log(apiData.apiParams);

      this.isLoading = true;
      this.$store.dispatch('CREATE_TRANSPORTER_REGISTRATION', apiData).then((ans) => {
        this.$buefy.toast.open({
          message: 'Заявка отправлена',
          type: 'is-success'
        });

        console.log('Номер заявки: ', ans);
        this.$store.commit('SET_HAVE_REQUEST_TRANSPORTER', true);
        this.$store.commit('SET_NUMBER_REQUEST_TRANSPORTER', ans);

        this.$router.push('/activity-feed');
      }).catch((message) => {
        this.$buefy.toast.open({
          message: 'Ошибка при создании заявки',
          type: 'is-danger'
        });
      }).finally(() => {
        this.isLoading = false;
      });
    },
    validateForm() {
      const { name, number } = this.dataApplication;
      const { vehicle_registration, drivers_license, evacuator } = this;

      // Простая проверка на пустые поля
      if (!name.trim() || !number.trim()) {
        return "Вы не заполнили обязательные поля";
      }

      // Простая проверка файлов
      if (vehicle_registration.length !== 2) {
        return "Не хватает фото свидетельства";
      }
      if (drivers_license.length !== 2) {
        return "Не хватает фото водительского удостоверения";
      }
      if (evacuator.length < 1) {
        return "Не хватает фото эвакуатора";
      }
      if (evacuator.length > 1) {
        return "Слишком много фото эвакуатора";
      }

      return true;
    },
    deleteDropFile(from_list, index) {
      from_list.splice(index, 1)
    }
  },
};
</script>

<style>
.login__button {
    text-align: center;
    margin-top: 20px;
}

.requestTransporter a {
  text-decoration: underline;
}

.text-center {
    text-align: center;
}

#applicationRegistration .upload {
  display: block;
}


</style>
